<template>
  <PageContainer
    :title="$translations['session-completed'].title"
    :action="actions.close"
  >
    <template v-slot:content>
      <template v-if="!isLoading">
        <OrientationCompleted
          :next-appointment="nextAppointment"
          v-if="appointmentType === 'ORIENTATION'"
        />

        <template v-else>
          <AppointmentReview
            :next-appointment="nextAppointment"
            :past-appointment="pastAppointment"
            :appointment-id="appointmentId"
          />
        </template>
      </template>

      <SessionCompletedSkeleton v-else />
    </template>
  </PageContainer>
</template>

<script>
import {
  Appointments,
  UpcomingAppointments,
  PageContainer
} from '@seliaco/red-panda'
import OrientationCompleted from './components/OrientationCompleted'
import SessionCompletedSkeleton from './components/SessionCompletedSkeleton'
import AppointmentReview from './components/AppointmentReview'
import { mapGetters } from 'vuex'
import { AfterCallEvent } from "@/types/events"

export default {
  name: 'SessionCompleted',
  components: {
    OrientationCompleted,
    SessionCompletedSkeleton,
    AppointmentReview,
    PageContainer
  },
  props: {
    appointmentId: String
  },
  data () {
    return {
      nextAppointment: null,
      pastAppointment: null,
      showAppointmentReview: false,
      appointmentType: null,
      canHaveFreeSession: false,
      specialist: null,
      packageServiceId: null,
      hasPackages: false,
      loading: {
        appointment: false,
        nextAppointment: false
      },
      actions: {
        close: {
          icon: 'icon-close',
          callback: () => {
            this.$router.push({ name: 'Home' })
          }
        }
      }
    }
  },
  mounted () {
    if (this.$store.getters['auth/user']) {
      this.mount()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/user'],
        () => {
          this.mount()
        }
      )
    }
  },
  methods: {
    mount () {
      this.getAppointment()
      this.getNextAppointment()
      window.history.pushState('forward', null)
      window.addEventListener('popstate', this.handlePopstate)
    },
    getAppointment () {
      const lang = this.$store.getters.language.lang
      this.loading.appointment = true
      Appointments.read({
        columns: `
          id,
          status,
          service:specialist_service (
            id,
            service_type (
              name:name_${lang},
              type
            )
          ),
          patient (id),
          specialist (
            id,
            speciality,
            user (
              first_name,
              last_name,
              profile_picture,
              address (
                full_address
              )
            )
          )
        `,
        eq: {
          id: this.appointmentId
        }
      })
        .then((response) => {
          this.pastAppointment = response.data[0]

          // emit viewed event
          AfterCallEvent.viewed({
            appointment_id: this.pastAppointment.id,
            specialist_id: this.pastAppointment.specialist.id,
            service_name: this.pastAppointment?.service?.service_type?.name
          })

          this.setAppointmentType()
        })
        .finally(() => (this.loading.appointment = false))
    },
    setAppointmentType () {
      this.appointmentType = this.pastAppointment?.service?.service_type?.type

      if (this.appointmentType === 'FIRST_CONTACT') {
        Appointments.read({
          columns: 'service:specialist_service!inner(service_type!inner(type))',
          eq: {
            'service.service_type.type': 'FIRST_CONTACT',
            patient: this.pastAppointment.patient.id
          }
        }).then((response) => {
          this.canHaveFreeSession = response?.data?.length < 3
        })
      }
    },
    getNextAppointment () {
      this.loading.nextAppointment = true
      UpcomingAppointments.getMyAppointments(
        'USER',
        this.$store.getters['auth/user'].id
      )
        .then((response) => {
          this.nextAppointment = response
          this.specialist = response.specialist
          this.packageServiceId = response.appointment?.specialist_service.id
        })
        .finally(() => (this.loading.nextAppointment = false))
    },
    handlePopstate () {
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    isLoading () {
      return this.loading.appointment || this.loading.nextAppointment
    }
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.handlePopstate)
  }
}
</script>
