<template>
  <div v-if="specialist && (nextAppointment || pastAppointment)">
    <div class="segment margin-y">
      <div
        class="segment-content"
        v-if="pastAppointment.status === 'NO_ATTEND_SPECIALIST'"
        v-html="
          $translations['session-completed']['no-attend-specialist'].description
        "
      />
      <div
        class="segment-content"
        v-html="$translations['session-completed'].other.description"
        v-else
      />
    </div>

    <!-- appointment -->
    <div class="page-segment">
      <div class="page-segment-content">
        <template>
          <div
            class="heading-small margin-bottom"
            v-html="
              $translations['session-completed']['no-attend-specialist'].title
            "
            v-if="pastAppointment.status === 'NO_ATTEND_SPECIALIST'"
          />
          <div
            class="heading-small margin-bottom"
            v-html="$translations['session-completed']['next-step']"
            v-else
          />
        </template>

        <NextAppointment
          :appointment="nextAppointment.appointment"
          v-if="nextAppointment && nextAppointment.appointment"
        />
        <BookAppointment :specialist="nextAppointment.specialist" v-else />
      </div>
    </div>

    <!-- packages -->
    <div class="page-segment" v-if="packages">
      <div class="page-segment-content">
        <div
          class="heading-small margin-bottom"
          v-html="$translations['session-completed'].other['package-title']"
        />
        <div class="body margin-bottom" v-html="packageDescriptionText" />

        <Packages
          :service-name="serviceNameText"
          :packages="packages.packages"
          :specialist-id="specialist.id"
        />
      </div>
    </div>
    <div class="page-segment" v-if="loadingPackage && !packages">
      <div class="page-segment-content">
        <Skeleton
          width="50%"
          height="24px"
          borderRadius="8px"
          class="margin-bottom"
        />

        <Skeleton
          width="100%"
          height="24px"
          borderRadius="8px"
          class="margin-bottom-lg"
        />
        <Skeleton
          width="40%"
          height="24px"
          borderRadius="8px"
          class="margin-bottom"
        />

        <Skeleton
          width="100%"
          height="161px"
          borderRadius="8px"
          class="margin-bottom-sm"
        />
        <Skeleton
          width="100%"
          height="161px"
          borderRadius="8px"
          class="margin-bottom-sm"
        />
        <Skeleton width="100%" height="161px" borderRadius="8px" />
      </div>
    </div>
    <ReviewDialog
      v-if="!hideReview"
      :title="$translations.aftercall.modals.reviews.title"
      :placeholder="$translations.aftercall.modals.reviews.placeholder"
      :description="$translations.aftercall.modals.reviews.description"
      :submit-button="$translations.aftercall.modals.reviews['submit-button']"
      :close-button="$translations.aftercall.modals.reviews['close-button']"
      :lang="language.lang"
      @close="hideReview = !hideReview"
      @submit="submittedV3"
    />
  </div>
</template>

<script>
import NextAppointment from './NextAppointment'
import BookAppointment from './BookAppointment'
import Packages from './Packages'
import {
  Promos,
  AftercallSurveyMixin,
  ReviewV3Dialog as ReviewDialog,
  Reviews,
  CurrencyFilter,
  Skeleton
} from '@seliaco/red-panda'
import { AfterCallEvent, ReviewEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentReview',
  components: {
    NextAppointment,
    BookAppointment,
    Packages,
    ReviewDialog,
    Skeleton
  },
  props: {
    nextAppointment: Object,
    pastAppointment: Object,
    appointmentId: String
  },
  mixins: [AftercallSurveyMixin],
  data () {
    return {
      packages: null,
      hideReview: false,
      specialist: null,
      service: null,
      origin: 'USER',
      surveyId: null,
      loadingPackage: false
    }
  },
  mounted () {
    this.mount()
  },
  methods: {
    mount () {
      if (this.pastAppointment?.status === 'NO_ATTEND_SPECIALIST') {
        this.hideReview = true
      }

      if (this.nextAppointment && this.nextAppointment.appointment) {
        this.service = {
          id: this.nextAppointment.appointment.specialist_service.id
        }
      } else {
        this.service = {
          id: this.pastAppointment?.service.id
        }
      }
      if (this.nextAppointment?.specialist) {
        this.specialist = {
          id: this.nextAppointment.specialist.id,
          profile_picture: this.nextAppointment.specialist.profile_picture,
          name: `${this.nextAppointment.specialist.first_name} ${this.nextAppointment.specialist.last_name}`
        }
        this.getPackages()
      }
    },
    getPackages () {
      this.loadingPackage = true
      Promos.Packages.listBySpecialistServices(this.specialist.id)
        .then((packages) => {
          this.packages = packages.find((pkg) => pkg.id === this.service.id)
        })
        .catch()
        .finally(() => (this.loadingPackage = false))
    },
    callSegmentEvent (answer) {
      const body = {
        user: { ...this.user },
        plaftorm: this.$store.getters.platform,
        version: this.$version,
        answer
      }
      AfterCallEvent.Survey_User_Success_Condition(body)
    },
    // DEPRECATED
    submitted () {
      this.hideReview = true
      this.getSurveyConfig(this.origin, this.appointmentId)
    },
    submittedV3 (data) {
      const createSpecialistReviewPromise = Reviews.createReviewV3({
        ...data.specialist,
        reference_id: this.pastAppointment.specialist.id,
        appointment: this.appointmentId,
        specialist: this.pastAppointment.specialist.id
      })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.aftercall.modals.reviews.toast.success
          })
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text:
              error.message ||
              this.$translations.aftercall.modals.reviews.toast.error
          })
        })
      const createAppointmentReviewPromise = Reviews.createReviewV3({
        ...data.meet,
        reference_id: this.appointmentId,
        appointment: this.appointmentId,
        specialist: this.pastAppointment.specialist.id
      })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.aftercall.modals.reviews.toast.success
          })
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text:
              error.message ||
              this.$translations.aftercall.modals.reviews.toast.error
          })
        })

      ReviewEvent.clickSubmit(data)
      Promise.all([
        createSpecialistReviewPromise,
        createAppointmentReviewPromise
      ])
        .finally(() => {
          this.hideReview = true
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    }),
    packageDescriptionText () {
      if (!this.packages) {
        return
      }

      const text =
        this.$translations['session-completed'].other['package-description']
      let amount = null

      amount =
        this.packages.packages[0].service_price.unit -
        this.packages.packages[0].service_price.with_discount

      const curreyncy =
        this.packages.packages[0].service_price.currency.toUpperCase()

      return text.replace('{{amount}}', CurrencyFilter(amount / 100, curreyncy))
    },
    serviceNameText () {
      if (this.packages) {
        const lang = this.$store.getters.language.lang
        return this.packages[`name_${lang}`]
      } else {
        return null
      }
    }
  }
}
</script>
