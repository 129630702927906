<template>
  <div>
    <div v-for="(item, key) in packages" :key="key">
      <div @click="handleSelectPackage" class="margin-bottom-sm">
        <PackageCard
          v-selectable-container="{ elementLevel: 1 }"
          :serviceName="serviceName"
          :packageData="item"
          :icon-name="item.service_icon_name"
          :specialist-id="specialistId"
          :show-button="false"
          :currency="item.service_price.currency"
          :can-open-detail="{ modal: true, view: false }"
          :show-addi="true"
          :is-clickable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PackageCard from '../../promos/packages/components/PackageCard'
import { selectableContainer } from '@seliaco/red-panda'
import { AfterCallEvent } from '@/types/events'

export default {
  name: 'Packages',
  props: {
    serviceName: String,
    packages: Array,
    specialistId: String
  },
  directives: { selectableContainer },
  components: {
    PackageCard
  },
  methods: {
    handleSelectPackage () {
      this.$segment.track(AfterCallEvent.select_package_aftercall)
    }
  }
}
</script>
