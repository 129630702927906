<template>
  <AppointmentCard :appointment="appointmentData" />
</template>

<script>
import { selectableContainer } from '@seliaco/red-panda'
import AppointmentCard from '@/components/appointment/AppointmentCard'

export default {
  name: 'NextAppointment',
  components: {
    AppointmentCard
  },
  directives: { selectableContainer },
  props: {
    appointment: null
  },
  computed: {
    appointmentData () {
      return {
        ...this.appointment,
        service: this.appointment.specialist_service
      }
    }
  }
}
</script>
