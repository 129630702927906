<template>
  <div>
    <div class="segment margin-y">
      <Skeleton height="24px" width="250px" margin="0 0 4px" border-radius="16px" />
      <Skeleton height="24px" width="72px" border-radius="16px" />
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <div class="heading-small margin-bottom" v-html="$translations['session-completed']['next-step']" />
        <Skeleton height="158px" width="100%" border-radius="16px" />
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'

export default {
  name: 'SessionCompletedSkeleton',
  components: {
    Skeleton
  }
}
</script>
