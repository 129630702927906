<template>
  <BookAppointmentCard
    :image="specialist.profile_picture"
    :subtitle="specialistNameText"
    :title="$translations['session-completed'].other['book-with']"
    :action="action"
  />
</template>

<script>
import { BookAppointmentCard } from '@seliaco/red-panda'

export default {
  name: 'BookAppointment',
  props: {
    specialist: null
  },
  data () {
    return {
      action: {
        text: this.$translations.appointments.schedule.title,
        callback: () => {
          this.$router.push({
            name: 'Schedule',
            query: {
              specialistId: this.specialist.id,
              back: this.$route.fullPath
            }
          })
        }
      }
    }
  },
  computed: {
    specialistNameText () {
      return `${this.specialist.first_name} ${this.specialist.last_name}`
    }
  },
  components: {
    BookAppointmentCard
  }
}
</script>
