<template>
  <div>
    <div class="segment margin-y">
      <div class="segment-content" v-html="$translations['session-completed'].orientation.description" />
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <div class="heading-small margin-bottom" v-html="$translations['session-completed']['next-step']" />

        <!-- next appointment -->
        <template v-if="nextAppointment.appointment">
          <div class="body margin-bottom" v-html="completePaymentText" />

          <NextAppointment :appointment="nextAppointment.appointment" />
        </template>

        <!-- no next appointment -->
        <template v-else>
          <div class="body margin-bottom" v-html="$translations['session-completed'].orientation.schedule" />

          <Button
            @clicked="navigateToWelcome"
            class="margin-bottom-sm"
            :text="$translations['session-completed'].orientation['recommend-specialist']"
          />

          <Button
            @clicked="navigateToSearch"
            type="outline"
            :text="$translations['session-completed'].orientation['search-specialist']"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'
import NextAppointment from './NextAppointment'
import { AfterCallEvent } from '@/types/events'
import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'

export default {
  name: 'OrientationCompleted',
  props: {
    nextAppointment: null
  },
  mixins: [NavigateMatchingResetMixin],
  data () {
    return {
      action: {
        text: this.$translations['session-completed']['complete-payment'],
        callback: () => {
          this.$router.push({
            name: 'CheckOut',
            params: {
              type: 'APPOINTMENT',
              id: this.nextAppointment.appointment.id
            }
          })
        }
      },
      arrowAction: {
        callback: () => {
          this.$router.push({
            name: 'AppointmentDetails',
            params: {
              id: this.nextAppointment.appointment.id
            }
          })
        }
      }
    }
  },
  computed: {
    completePaymentText () {
      if (!this.nextAppointment.appointment) {
        return
      }

      const text = this.$translations['session-completed'].orientation['complete-payment']
      const specialist = this.getSpecialistName()

      return text.replace('{{specialist}}', specialist)
    }
  },
  methods: {
    navigateToSearch () {
      this.$segment.track(AfterCallEvent.select_searchmyspecialist_aftercall)

      this.$router.push({
        name: 'Search'
      })
    },
    navigateToWelcome () {
      this.$segment.track(AfterCallEvent.select_respondquestionnaire_after)
      this.handleMatchingNavigation()
    },
    getSpecialistName () {
      if (!this.nextAppointment.appointment) {
        return
      }

      const specialistUser = this.nextAppointment.appointment.specialist.user
      return `${specialistUser.first_name} ${specialistUser.last_name}`
    }
  },
  components: {
    Button,
    NextAppointment
  }
}
</script>
